










































































































import Component from 'vue-class-component'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniToggleSwitch from '@/components/inputs/SygniToggleSwitch.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniDroparea from '@/components/inputs/SygniDroparea.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { AddProductSelects } from '@/modules/shared/dictionary-store/types';
import { mapGetters } from 'vuex';
import { Wallet } from '@/shared/interfaces/Wallet';
import { Prop, Watch } from 'vue-property-decorator';
import AddNewCampaignBoilerplate from './AddNewCampaignBoilerplate.vue';
import { NewProduct, TimeTypes } from '../../store/types';
import { SelectOption } from '@/store/types';
import { Template } from '@/modules/genprox/modules/fund/modules/fundraising/modules/templates/store/types'
import { Campaign } from '../../modules/campaigns/store/types';
import _ from 'lodash';
import SygniTabs from '@/components/layout/SygniTabs.vue';
import { emptyCampaignProduct } from '../../modules/campaigns/store';
import { usedCurrencies } from '@/shared/consts';
import { TrashIcon, PlusCircleIcon } from '@/assets/icons/heroicons/heroicons';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: { SygniContainerTitle, SygniDroparea, SygniInput, SygniCheckbox, SygniTextArea, SygniSelect, SygniRectButton, SygniLinkButton, SygniDatePicker, SygniToggleSwitch, SygniRadio, SygniTabs, GenproxButton, TrashIcon, PlusCircleIcon },
  computed: {
    ...mapGetters('dictionary', {
      productSelects: 'addProductsSelects'
    }),
    ...mapGetters('genprox', {
      activeContextWallets: 'activeContextWallet'
    }),
  }
})
export default class AddNewCampaignFormStepFour extends AddNewCampaignBoilerplate {
  PlusCircleIcon = PlusCircleIcon;
  TrashIcon = TrashIcon;

  @Prop({ default: null }) campaign: Campaign;
  @Prop({ default: null }) validation: any;
  usedCurrencies: any = usedCurrencies;
  activeContextWallets!: Array<Wallet>;
  productSelects!: AddProductSelects;
  advancedOptions: boolean = false;
  templatesList: Template[] = [];
  isInitialized: boolean = false

  defaultTabOption: string = 'variant-1';
  selectedTab: string = 'variant-1'

  additionalProductsData: { fixedReplacementDay: boolean, hasInvestmentValueStep: boolean }[] = []

  get productVariantTabs() {
    const tabs: any = []

    this.campaignData?.products?.forEach((product: any, i: number) => {
      tabs.push({ label: `Variant ${i + 1}`, key: `variant-${i + 1}`, hasErrors: this.validation?.campaignData?.products[i]?.$error || this.validation?.campaignData?.products?.$each[i]?.$error })
    })

    return tabs
  }

  get activeContextWalletsOptions() {
    return this.activeContextWallets.map(wallet => ({ label: this.$options.filters.bankAccountNumber(wallet.account), value: wallet.account }))
  }

  get hasId() {
    return this.$route?.params?.id ? true : false;
  }

  get isActiveCampaign() {
    return !!(this.campaignData?.status?.toLowerCase() === 'active')
  }

  get attachmentOptions(): SelectOption[] {
    return this.templatesList?.map((el: any) => {
      let title: string = el.title
      const obj: any = { label: title, value: el.id }

      if (this.activeUserData?.context?.id !== el?.legalEntityId) {
        obj.legalEntityName = el?.legalEntityName
      }

      return obj
    })
  }

  isIborVisible(index: number) {
    return !!(this.campaignData.products[index]?.data?.interestType?.toUpperCase() === 'VARIABLE')
  }

  isInterestTypeVariableDisabled(index: number = 0) {
    return !(this.campaignData?.products?.length && this.campaignData?.products[index]?.data?.interestCalculationMethod === 'Actual/365 (V)' && (this.campaignData?.products[index]?.currency === 'PLN' || this.campaignData?.products[index]?.currency === 'EUR'))
  }

  selectTab(value: string) {
    this.selectedTab = value;
    this.$nextTick(() => {
      this.reRender++
    })
  }

  removeVariant() {
    const currentProductIndex = Number(this.selectedTab?.split('-')[1]) - 1
    this.campaignData?.products?.splice(currentProductIndex, 1)
    this.additionalProductsData?.splice(currentProductIndex, 1);
    (this.$refs.tabs as SygniTabs).setOption(`variant-1`);
    this.$nextTick(() => {
      this.reRender++
      this.validation?.$reset();
    })
    this.setCampaignData()
  }

  addNewVariant() {
    this.campaignData?.products?.push(_.cloneDeep(emptyCampaignProduct))
    this.additionalProductsData?.push({
      fixedReplacementDay: true,
      hasInvestmentValueStep: false,
    });
    const currentProductIndex = Number(this.campaignData?.products?.length);
    (this.$refs.tabs as SygniTabs).setOption(`variant-${currentProductIndex}`);
    this.$nextTick(() => {
      this.reRender++
      this.validation?.$reset();
    })
    this.setCampaignData()
  }

  get interestTypes() {
    let interestTypes = this.productSelects.interestType

    interestTypes = interestTypes?.map((el: SelectOption) => {
      if (el?.value?.toUpperCase() === 'VARIABLE') {
        const currentProductIndex = Number(this.selectedTab?.split('-')[1]) - 1
        el.disabled = this.isInterestTypeVariableDisabled(currentProductIndex)
      }

      return el
    })

    return interestTypes
  }

  get iborTypes() {
    const currentProductIndex = Number(this.selectedTab?.split('-')[1]) - 1
    return this.$store.getters['products/getIborTypes'] ? this.$store.getters['products/getIborTypes'][this.campaignData?.products[currentProductIndex]?.currency] || [] : []
  }

  setFixedReplacementDay(value: string, index: number) {
    this.$set(this.additionalProductsData[index], 'fixedReplacementDay', value)
    this.removeUnselectedProductData(index)
  }
  
  setInvestmentValueStep(value: boolean, index: number) {
    this.$set(this.additionalProductsData[index], 'hasInvestmentValueStep', value)
    if (!value) {
      this.campaignData.products[index].investmentValueStep = null
      this.setCampaignData()
    }
  }

  setIborType(value: string, index: number) {
    this.$set(this.campaignData.products[index].data, 'iborType', value || null)
  }

  setCurrency(value: string, index: number) {
    this.$set(this.campaignData.products[index], 'currency', value)

    if (this.isInterestTypeVariableDisabled(index)) {
      this.$set(this.campaignData.products[index].data, 'interestType', 'Fixed')
      this.$set(this.campaignData.products[index].data, 'iborType', null)
    } else {
      if (this.campaignData?.products[index]?.data?.interestType?.toUpperCase() !== 'VARIABLE') {
        this.$set(this.campaignData?.products[index]?.data, 'iborType', null)
      } else {
        if (this.iborTypes?.length > 0) {
          if (this.iborTypes?.length === 1) {
            this.$set(this.campaignData?.products[index]?.data, 'iborType', this.iborTypes[0]?.value || null)
          }
        } else {
          this.$set(this.campaignData?.products[index]?.data, 'iborType', null)
        }
  
        if (!this.campaignData.products[index].data.simpleOptions?.interest) {
          this.setEnabledAdvancedOptions(true, index)
        }
      }
    }

    this.removeUnselectedProductData(index)
  }

  setInterestCalculationMethod(value: any, index: number) {
    this.$set(this.campaignData.products[index].data, 'interestCalculationMethod', value)
    if (this.isInterestTypeVariableDisabled(index)) {
      this.$set(this.campaignData.products[index].data, 'interestType', 'Fixed')
      this.$set(this.campaignData.products[index].data, 'iborType', null)
    }

    this.removeUnselectedProductData(index)
  }

  setInterestType(value: string, index: number) {
    if (!this.interestTypes?.find((el: SelectOption) => el?.value === value)?.disabled) {
      this.campaignData.products[index].data.interestType = value
    }

    if (this.campaignData?.products[index]?.data?.interestType?.toUpperCase() !== 'VARIABLE') {
      this.$set(this.campaignData?.products[index]?.data, 'iborType', null)
    } else {
      if (this.iborTypes?.length > 0) {
        if (this.iborTypes?.length === 1) {
          this.$set(this.campaignData?.products[index]?.data, 'iborType', this.iborTypes[0]?.value || null)
        }
      } else {
        this.$set(this.campaignData?.products[index]?.data, 'iborType', null)
      }

      if (!this.campaignData.products[index].data.simpleOptions?.interest) {
        this.setEnabledAdvancedOptions(true, index)
      }
    }

    this.setCampaignData()
  }

  setDurationType(value: string, index: number = 0) {
    if (this.campaignData.products[index].data.duration === null) {
      this.campaignData.products[index].data.duration = {
        type: value,
        count: undefined,
      }
    } else {
      this.campaignData.products[index].data.duration.type = value;
    }

    this.setCampaignData();
  }

  setDurationCount(value: number, index: number = 0) {
    if (this.campaignData.products[index].data.duration === null) {
      this.campaignData.products[index].data.duration = {
        type: undefined,
        count: value,
      }
    } else {
      this.campaignData.products[index].data.duration.count = value;
    }

    this.setCampaignData();
  }

  setEnabledAdvancedOptions(value: boolean, index: number = 0) {
    this.campaignData.products[index].data.enabledAdvancedOptions = value;

    this.removeUnselectedProductData(index);
    if (this.campaignData.products[index].data.enabledAdvancedOptions) {
      this.campaignData.products[index].data.advancedOptions = {
        commission: null,
        commissionPayment: 'Monthly',
        commissionType: 'percent',
        margin: null,
        marginPayment: 'Monthly',
        marginType: 'percent'
      }
    } else {
      this.campaignData.products[index].data.simpleOptions = {
        interest: null,
        interestPayment: 'Monthly',
      }
    }

    this.setCampaignData();
  }

  setCommissionPA(value: any, index: number = 0) {
    this.campaignData.products[index].data.advancedOptions.commission = value || 0;

    this.setCampaignData();
  }

  setCommissionType(value: any, index: number = 0) {
    this.campaignData.products[index].data.advancedOptions.commissionType = value;

    this.setCampaignData();
  }

  setMarginType(value: any, index: number = 0) {
    this.campaignData.products[index].data.advancedOptions.marginType = value;

    this.setCampaignData();
  }

  setMarginPA(value: any, index: number = 0) {
    this.campaignData.products[index].data.advancedOptions.margin = value || 0;

    this.setCampaignData();
  }

  setCommissionsPayment(value: any, index: number = 0) {
    this.campaignData.products[index].data.advancedOptions.commissionPayment = value;

    this.setCampaignData();
  }

  setMarginPayment(value: any, index: number = 0) {
    this.campaignData.products[index].data.advancedOptions.marginPayment = value;

    this.setCampaignData();
  }

  removeUnselectedProductData(index: number = 0): void {
    if (this.additionalProductsData[index]?.fixedReplacementDay) {
      this.campaignData.products[index].data.timeType = TimeTypes.fixedRepaymentDate;
      this.campaignData.products[index].data.duration = null;
    } else {
      this.campaignData.products[index].data.timeType = TimeTypes.duration;
      delete this.campaignData.products[index].data.fixedRepaymentDate;
    }

    if (this.campaignData.products[index].data.enabledAdvancedOptions) {
      this.campaignData.products[index].data.simpleOptions = null;
    } else {
      this.campaignData.products[index].data.advancedOptions = null;
    }

    this.setCampaignData();
  }

  setAgreementTemplateId(value: string, index: number) {
    this.campaignData.products[index].agreementTemplateId = value;
    this.setCampaignData();
  }

  setAnnotation(value: string, index: number) {
    this.campaignData.products[index].annotation = value;
    this.setCampaignData();
  }

  setDebtor(value: string, index: number) {
    const option: any = this.activeContextWallets.find((el: any) => el.account === value);

    if(option?.account) {
      const debtor: any = {
        account: option.account,
        name: option.name,
        currency: option.currency,
        type: option.type,
      }
  
      this.campaignData.products[index].data.debtor = debtor;
    } else {
      this.campaignData.products[index].data.debtor = null;
    }


    this.setCampaignData();
  }

  setCampaignOptions() {
    this.campaignData?.products?.forEach((product: NewProduct) => {
      this.additionalProductsData.push({
        fixedReplacementDay: product?.data?.timeType === TimeTypes.fixedRepaymentDate ? true : false,
        hasInvestmentValueStep: !!(Number(product?.investmentValueStep || 0) > 0),
      })
    })
  }

  mounted() {
    this.$nextTick(() => {
      this.isInitialized = true
    })
  }

  async beforeMount() {
    this.setCampaignOptions()
    const templates = await this.$store.dispatch('templates/getTemplateChoices', { type: 'template', context: this.campaignData?.issuerId ? this.campaignData?.issuerId : this.activeUserData?.context?.id } )
    this.templatesList = templates
    this.loadCampaignData()
  }

  @Watch('campaign', { deep: true, immediate: true }) onCampaignChange() {
    this.campaignData = _.cloneDeep(this.campaign)
  }
}
